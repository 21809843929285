@font-face {
  font-family: "Gotham";
  src: url("./fonts/Gotham-Light.woff") format("woff"),
    url("./fonts/Gotham-Light.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/Gotham-Medium.woff") format("woff"),
    url("./fonts/Gotham-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

h1,
h2,
p {
  padding: 0;
  margin: 0;
  font-family: "Gotham", sans-serif;
  text-align: left;
}

h1 {
  width: 284px;
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
}

h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 28.8px;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
}

body {
  min-width: 390px;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

input,
textarea,
select,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Основная структура */
.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #f4f4f9;
  gap: 50px;
}

.main-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  max-width: 1680px;
  margin: 0 auto;
  margin-bottom: 10px;
  gap: 40px;
  box-sizing: border-box;
}

.top-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 49%;
  max-width: 828px;
}

label {
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 400;
  color: rgba(96, 96, 96, 1);
}

/* Основные стили для контейнера */
.checkbox-container {
  display: flex;
  align-items: center;
  background: white;
  padding: 11px;
  border-radius: 25px;
  border: 1px solid rgba(231, 233, 240, 1);
  cursor: pointer;
  transition: background-color 0.3s ease;
  gap: 15px;
  position: relative;
  width: fit-content;
}

/* Скрываем стандартный чекбокс */
.checkbox-container input[type="checkbox"] {
  display: none;
}

/* Стили для кастомного чекбокса */
.checkbox-container label {
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 400;
  color: rgba(39, 40, 34, 1);
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* Кастомный квадрат чекбокса */
.checkbox-container label::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 1px solid rgba(231, 233, 240, 1);
  border-radius: 10px;
  background-color: white;
  margin-right: 10px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

/* Стили для состояния "checked" */
.checkbox-container input[type="checkbox"]:checked + label::before {
  background-color: rgba(29, 235, 149, 1);
  border: 1px solid rgba(29, 235, 149, 1);
}

.content-columns {
  display: flex;
  justify-content: space-between;
  width: 1680px;
  margin: 0 auto;
}

.left-column {
  width: 50%;
  max-width: 828px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Хедер */
.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 1680px;
  padding: 30px 0;
  background-color: inherit;
}

.logo img {
  width: 240px;
  height: auto;
}

/* Стили для радиокнопок */
.checkboxes-row {
  display: flex;
  gap: 20px;
}

.custom-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.custom-radio input[type="radio"] {
  display: none;
}

/* Стили для кастомной радиокнопки */
.custom-radio-box {
  width: 22px;
  height: 22px;
  margin-right: 10px;
  border: 1px solid rgba(231, 233, 240, 1);
  border-radius: 4px;
  display: inline-block;
  position: relative;
  transition: background-color 0.3s, border-color 0.3s;
}

.custom-radio-box::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  border-radius: 7px;
  background-color: white;
  transition: background-color 0.3s;
}

/* Стили для активной радиокнопки */
.custom-radio input[type="radio"]:checked + .custom-radio-box {
  background-color: white;
  border-radius: 7px;
  border: 1px solid rgba(231, 233, 240, 1);
}

.custom-radio input[type="radio"]:checked + .custom-radio-box::before {
  background-color: rgba(29, 235, 149, 1);
  border-radius: 4px;
}

.custom-radio input[type="radio"]:checked + .custom-radio-box + .radio-text {
  font-weight: 500;
}

/* Карточки */
.card {
  background-color: #fff;
  padding: 30px;
  border-radius: 24px;
}
/* По умолчанию содержимое скрыто на мобильных устройствах */
.card-content {
  max-height: none; /* На десктопе карточка всегда открыта */
  overflow: visible;
  transition: max-height 0.3s ease;
}

/* Когда карточка открыта, содержимое разворачивается */
.card-content.open {
  max-height: 500px; /* Можно настроить, чтобы вмещать содержимое */
}

/* Степпер */
.stepper {
  width: 50%;
  max-width: 828px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.stepper-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.stepper-buttons button {
  width: 100%;
  padding: 20px;
  font-family: "Gotham", sans-serif;
  font-size: 16px;
  font-weight: 400;
  background-color: white;
  border: none;
  border-radius: 24px;
  cursor: pointer;
}

.stepper-buttons button.active {
  border: none;
  font-size: 16px;
  font-weight: 500;
}

.stepper-content {
  padding: 30px;
  background-color: #fff;
  border-radius: 24px;
}

/* DetailCard */

.detail-section {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(231, 233, 240, 1);
}

.detail-section:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.detail-wrapper {
  display: flex;
  flex-direction: column;
  width: 48%;
}

.detail-left,
.detail-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 48%;
  margin-bottom: 30px;
}

.detail-left p,
.detail-right p {
  font-family: "Gotham", sans-serif;
  margin: 0;
  padding-bottom: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  color: #666;
  white-space: nowrap;
}

.detail-left span,
.detail-right span {
  font-family: "Gotham", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 28.8px;
  white-space: nowrap;
}

.percent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.highlight {
  background: rgba(29, 235, 149, 0.15);
  padding: 3px 6px;
  border-radius: 7px;
  font-weight: 500;
  font-size: 16px;
  color: rgba(29, 235, 149, 1);
  margin-left: 10px;
}

.highlight-blue {
  background: rgba(29, 91, 235, 0.15);
  padding: 3px 6px;
  border-radius: 7px;
  font-weight: 500;
  font-size: 16px;
  color: rgba(29, 91, 235, 1);
  margin-left: 10px;
}

.slider {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  height: 6px;
  background: rgba(29, 235, 149, 1);
  outline: none;
  opacity: 0.7;
  transition: 0.15s ease-in-out, opacity 0.15s ease-in-out;
  border-radius: 3px;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: rgba(29, 235, 149, 1);
  border-radius: 50%;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: rgba(29, 235, 149, 1);
  border-radius: 50%;
  cursor: pointer;
}

.mortgage-card h2 {
  padding-bottom: 30px;
  margin: 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 28.8px;
}

.upper-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.left-column-mortgage,
.right-column-mortgage {
  width: 48%;
}

.field {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 4px;
  margin-bottom: 30px;
}

.field-one {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 4px;
  margin-bottom: 20px;
}

.field-two {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 4px;
}

.field-three {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 4px;
  margin-bottom: 40px;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.input-wrapper span {
  font-size: 26px;
  line-height: 31.2px;
}

.month-pay {
  font-size: 32px;
}

.price-amount {
  display: flex;
  align-items: center;
}

.slider-input {
  width: 60%;
  margin-bottom: 16px;
  padding: 4px;
  display: flex;
  align-items: center;
  border: 1px solid RGB(233, 233, 233);
  border-radius: 8px;
}

.slider-input-field {
  font-size: 26px;
  line-height: 31.2px;
  border: none;
  outline: none;
  background-color: transparent;
  -moz-appearance: textfield;
  cursor: pointer;
  transition: font-weight 0.2s ease-in-out;
  font-weight: 300;
}

.slider-input-percent {
  width: auto;
  max-width: 110px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 8px;
  font-size: 24px;
  font-weight: 500;
  line-height: 28.8px;
  white-space: nowrap;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.period-wrapper {
  display: flex;
  justify-content: center;
}

.period-input-container {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.period-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.year-month,
.month {
  width: 50px;
  text-align: center;
  margin-right: 5px;
}

.year-month {
  width: 50px;
}

.months-label {
  margin-right: 10px;
}

.period-input-container:last-child {
  margin-right: 0;
}

.lower-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.left-lower-mortgage {
  width: 48%;
}

.right-lower-mortgage {
  padding: 4px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

/* Кнопки */
.buttons {
  display: flex;
  gap: 10px;
}

.download-pdf,
.reset {
  padding: 16px 25px;
  border-radius: 5px;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
  border-radius: 14px;
}

.download-pdf {
  background: rgba(238, 239, 242, 1);
  color: rgba(39, 40, 34, 1);
  border: none;
}

.reset {
  background-color: transparent;
  border: 1px solid rgba(238, 239, 242, 1);
  color: rgba(39, 40, 34, 1);
}

.two-column-small-card {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.two-column-small-card .left-column,
.two-column-small-card .right-column {
  width: 48%;
}

.small-left-column,
.small-right-column {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.small-card {
  background-color: #fff;
  border-radius: 24px;
  padding: 30px;
  transition: max-height 0.3s ease;
}

.card-header {
  padding-bottom: 30px;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  width: 100%;
}

.one-column-card {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.profit-amount {
  font-size: 36px;
  font-weight: 600;
  color: rgba(39, 40, 34, 1);
}

.profit-percentage {
  font-size: 26px;
  font-weight: 500;
  color: rgba(29, 235, 149, 1);
}

.table-row {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

/* TABLE  */
.table-row:last-child {
  border-bottom: none;
}

.table-row,
.table-header-row {
  display: flex;
}

.table-cell,
.table-cell-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
  font-family: "Gotham", sans-serif;
  font-size: 14px;
  line-height: 16.8px;
}

.table-cell {
  white-space: nowrap;
}

.table-left {
  justify-content: flex-start;
  text-align: left;
}

.table-cell:first-child,
.table-cell-top:first-child {
  text-align: left;
  justify-content: flex-start;
  flex: 0.5;
}

.table-cell-top {
  padding-bottom: 20px;
  font-weight: 500;
  border-bottom: 1px solid rgba(231, 233, 240, 1);
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-btn {
  display: flex;
  justify-content: center;
}

.show-more-btn {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border: none;
  background-color: rgba(238, 239, 242, 1);
  cursor: pointer;
  font-size: 16px;
  border-radius: 8px;
  gap: 10px;
}

.start-date {
  text-align: right;
  font-family: "Gotham", sans-serif;
  font-size: 14px;
  color: black;
}

.payment-schedule {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}
/*  */

.interval-selector {
  display: flex;
  align-items: center;
  font-family: "Gotham", sans-serif;
  font-size: 14px;
  color: #666;
  gap: 13px;
}

.custom-select {
  position: relative;
  display: inline-block;
}

.custom-select select {
  appearance: none;
  background-color: white;
  border: 1px solid rgba(231, 233, 240, 1);
  border-radius: 8px;
  padding: 10px 30px 10px 10px;
  font-size: 14px;
  cursor: pointer;
  font-family: "Gotham", sans-serif;
  font-weight: 500;
  color: #000;
  outline: none;
  width: 120px;
}

.custom-arrow {
  position: absolute;
  right: 10px;
  top: 54%;
  transform: translateY(-50%);
  pointer-events: none;
}

.custom-select select:focus {
  border-color: #1deb95;
}

.custom-select select option {
  background-color: white;
  color: #000;
  font-family: "Gotham", sans-serif;
  padding: 10px;
  font-size: 14px;
}

.custom-select select::-ms-expand {
  display: none;
}

.interest-rate-input {
  display: flex;
}

/* Общий стиль для инпутов */
.interest-input {
  width: 100%;
  font-size: 26px;
  line-height: 31.2px;
  border: none;
  outline: none;
  background-color: transparent;
  -moz-appearance: textfield;
  cursor: pointer;
  transition: font-weight 0.2s ease-in-out;
  font-weight: 300;
}

.rental-period,
.realization-period {
  margin-bottom: 16px;
}

/* Состояние ховера, при котором показывается иконка редактирования */
.slider-input:hover svg {
  visibility: visible;
  opacity: 1;
}

.slider-input svg {
  visibility: hidden;
  transition: visibility 0.2s, opacity 0.2s;
  opacity: 0;
}

.interest-input:focus {
  font-weight: 700;
}

.interest-input.filled {
  font-weight: 700;
}

.currency-sign,
.period-unit {
  font-weight: 300;
  transition: font-weight 0.2s ease-in-out;
}

.filled + .currency-sign,
.filled + .period-unit {
  font-weight: 700;
}

.filled {
  font-weight: bold;
}

.percent-sign {
  transition: font-weight 0.2s ease-in-out;
}

.percent-sign:focus {
  font-weight: 700;
}

.percent-sign.filled {
  font-weight: 700;
}

.interest-input::placeholder {
  font-size: 16px;
  color: #888;
}

.interest-input::-webkit-outer-spin-button,
.interest-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.chart-container {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 20px;
}

.bar {
  cursor: pointer;
}

.tooltip {
  position: fixed;
  background-color: gray;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  pointer-events: none;
  font-size: 12px;
}

.chart-title {
  font-size: 1.5em;
  text-align: center;
  margin-bottom: 10px;
}

.chart-items {
  display: flex;
  justify-content: left;
  margin-bottom: 20px;
  margin-top: 20px;
}

.items-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.items-color {
  width: 24px;
  height: 24px;
  border-radius: 8px;
  margin-right: 10px;
}

.income-color {
  background-color: rgba(130, 234, 195, 1);
}

.expense-color {
  background-color: rgba(238, 239, 242, 1);
}

.chart-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chart-right {
  display: flex;
  align-items: center;
  gap: 30px;
}

.mobile-detail-h2 {
  display: none;
}

.input-percent {
  display: flex;
  justify-content: space-between;
}

.tooltip-wrapper {
  position: relative;
  display: inline-block;
}

.tooltip-info {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgb(161 161 161);
  color: #fff;
  padding: 10px;
  border-radius: 8px;
  margin-top: 5px;
  z-index: 1000;
  width: 250px;
}

.qrcode-block {
  display: flex;
  align-items: center;
  gap: 20px;
}

.qrcode-text {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.qrcode-image {
  width: 13%;
  height: 13%;
}

.qrcode-button {
  padding: 10px;
  border-radius: 5px;
  border: none;
}

/* MOBILE */

@media (max-width: 1900px) {
  h1 {
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    text-align: left;
    width: 50%;
  }
  h2 {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: left;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
  }

  .input-wrapper span {
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    white-space: nowrap;
  }

  .header {
    width: 100%;
    max-width: 828px;
    padding: 10px 0;
  }

  .main-content {
    padding: 0;
    align-items: center;
  }

  .top-section {
    width: 100%;
    padding: 0 10px;
    margin-bottom: 0px;
  }

  .left-column {
    width: 100%;
    margin-bottom: 30px;
  }

  .card {
    padding: 20px;
  }

  .card-content {
    max-height: 0;
    overflow: hidden;
  }

  .card-header {
    padding-bottom: 0;
  }

  .mortgage-card h2 {
    padding-bottom: 20px;
  }

  .small-card.open .card-content {
    max-height: 1000px;
  }

  .content-columns {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .upper-section {
    flex-direction: column;
    margin-bottom: 16px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(231, 233, 240, 1);
  }

  .left-column-mortgage,
  .right-column-mortgage {
    width: 100%;
  }

  .left-lower-mortgage {
    width: 100%;
  }

  .left-column-mortgage {
    margin-bottom: 15px;
  }

  .lower-section {
    flex-direction: column;
  }

  .two-column-small-card {
    flex-direction: column;
    gap: 0px;
  }

  .small-left-column {
    margin-bottom: 20px;
  }

  .field {
    width: 100%;
    margin-bottom: 15px;
  }

  .field-one {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 4px;
    margin-bottom: 10px;
  }

  .field-two {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 4px;
    /* margin-bottom: 15px; */
  }

  .field-three {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 4px;
    margin-bottom: 10px;
  }

  .fix-slider {
    margin-bottom: 10px;
  }

  .row {
    flex-direction: column;
    gap: 10px;
  }

  .small-card {
    background-color: #fff;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    transition: gap 0.3s ease;
  }

  .small-card.open {
    gap: 20px;
  }

  .small-card:not(.open) {
    gap: 0;
  }

  .card-content {
    max-height: 0;
    overflow: hidden;
  }

  .checkboxes-row {
    margin-bottom: 30px;
  }

  .right-lower-mortgage {
    padding: 0px;
  }

  .stepper {
    width: 100%;
  }

  .stepper-content {
    padding: 20px;
    margin-bottom: 30px;
  }

  .detail-section {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .detail-wrapper {
    width: 100%;
  }

  .detail-left {
    width: 100%;
    margin-bottom: 10px;
  }

  .detail-right {
    width: 100%;
    margin-bottom: 20px;
  }

  .last {
    margin-bottom: 0;
  }

  .detail-left span,
  .detail-right span {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }

  /* TABLE */

  .payment-schedule {
    width: 100%;
  }

  .table-header {
    margin-bottom: 20px;
  }

  .table-scroll {
    overflow-x: auto;
    width: 100%;
  }

  .table-row {
    display: flex;
    justify-content: flex-start;
    min-width: 1000px;
  }

  .table-cell,
  .table-cell-top {
    flex: 1;
    min-width: 150px;
    padding: 10px;
    text-align: center;
  }

  .table-cell-top {
    font-weight: bold;
  }

  .table-row .table-cell:first-child,
  .table-header-row .table-cell-top:first-child {
    flex: 0 0 50px;
    text-align: left;
    min-width: 50px;
  }

  .table-btn {
    text-align: center;
    margin-top: 20px;
  }

  .show-more-btn {
    background-color: #f4f4f9;
    padding: 16px 25px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .show-more-btn svg {
    margin-left: 10px;
  }

  /* CHART */

  .chart-container {
    height: 250px;
  }

  .chart-right {
    width: 100%;
    align-items: baseline;
    justify-content: space-between;
  }

  .chart-footer {
    flex-direction: column;
    align-items: baseline;
  }

  .interval-selector {
    flex-direction: column;
  }

  /* .custom-select .dropdown-content {
    bottom: 100%;
    top: auto;
    transform: translateY(-10px);
  } */

  .mobile-detail-h2 {
    display: flex;
    font-size: 1.5em;
    text-align: center;
    margin-bottom: 20px;
  }

  .slider-input {
    width: 100%;
  }

  .year-month,
  .month {
    width: 70px;
  }

  .profit-wrap {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
  }

  .tooltip-info {
    right: 0;
    left: auto;
    /* transform: none; */
  }
}
